import React from 'react'
import { Modal, Button, Form } from "react-bootstrap";

export default function ModalCreate({action, state, handleCloseModal, alert, formik, validationFormik, setAlert, handleSave}) {
  return (
    <Modal
      show={state}
      onHide={handleCloseModal}
    >
      <Modal.Header closeButton>
        <Modal.Title>{action === 'create' ? 'Add Membership Rank' : 'Update Membership Rank'}</Modal.Title>
      </Modal.Header>
      <div className="p-4 text-danger">{alert}</div>
      <div className="p-4">
        <Form.Group>
          <Form.Label>Name*</Form.Label>
          <Form.Control
            type="text"
            name="name"
            {...formik.getFieldProps("name")}
            onChange={(e) => {
              formik.setFieldValue("name", e.target.value);
            }}
            className={validationFormik("name")}
            required
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.name}
              </div>
            </div>
          ) : null}
        </Form.Group>

        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            type="text"
            name="description"
            {...formik.getFieldProps("description")}
            className={validationFormik("description")}
            required
          />
          {formik.touched.description && formik.errors.description ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.description}
              </div>
            </div>
          ) : null}
        </Form.Group>
        <Form.Group>
          <Form.Label>Points Required</Form.Label>
          <Form.Control
            type="number"
            name="points_required"
            {...formik.getFieldProps("points_required")}
            className={validationFormik("points_required")}
            required
          />
          {formik.touched.points_required && formik.errors.points_required ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.points_required}
              </div>
            </div>
          ) : null}
        </Form.Group>
        <Form.Group>
          <Form.Label>Benefit Description</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            type="text"
            name="benefit_description"
            {...formik.getFieldProps("benefit_description")}
            className={validationFormik("benefit_description")}
            required
          />
          {formik.touched.benefit_description && formik.errors.benefit_description ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.benefit_description}
              </div>
            </div>
          ) : null}
        </Form.Group>
        <Form.Group>
          <Form.Label>Rank Number</Form.Label>
          <Form.Control
            type="number"
            name="rank_number"
            {...formik.getFieldProps("rank_number")}
            className={validationFormik("rank_number")}
            required
          />
          {formik.touched.rank_number && formik.errors.rank_number ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.rank_number}
              </div>
            </div>
          ) : null}
        </Form.Group>
        <Form.Group>
          <Form.Label>Benefit Discount Rate</Form.Label>
          <Form.Control
            type="number"
            name="benefit_discount_rate"
            {...formik.getFieldProps("benefit_discount_rate")}
            className={validationFormik("benefit_discount_rate")}
            required
          />
          {formik.touched.benefit_discount_rate && formik.errors.benefit_discount_rate ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.benefit_discount_rate}
              </div>
            </div>
          ) : null}
        </Form.Group>
        <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
          <Button
            variant="outline-secondary"
            onClick={() => {
              handleCloseModal();
              setAlert(null);
            }}
            style={{ width: 100 }}
            className="ml-3 mr-3"
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            style={{ width: 100 }}
            onClick={handleSave}
            className="ml-3 mr-3"
          >
            Save
          </Button>
        </div>
      </div>
    </Modal>
  )
}
