import React from 'react'
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

export default function ModalUpdateOutelt({
  state, 
  close,
  formik,
  loading
}) {
  return (
    <div>
      <Modal
        show={state}
        onHide={() => {
          close();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Outlet: </Modal.Title>
        </Modal.Header>
        <div className="p-4 text-danger">{alert}</div>
        <Form onSubmit={formik.handleSubmit}>
          <div className="p-4">
            <Row>
              <Col>
                <Form.Group style={{ width: "100%" }}>
                  <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                    CZ User:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="CZ User . . ."
                    {...formik.getFieldProps("cz_user")}

                  />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                    CZ PIN:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="CZ PIN . . ."
                    {...formik.getFieldProps("cz_pin")}

                  />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                    CZ Entity ID:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="CZ Entity ID . . ."
                    {...formik.getFieldProps("cz_entity_id")}

                  />
                </Form.Group>
                <Form.Group style={{ width: "100%" }}>
                  <Form.Label style={{ alignSelf: "center", marginBottom: "0" }}>
                    CZ Vendor Identifier:
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="CZ Vendor Identifier . . ."
                    {...formik.getFieldProps("cz_vendor_identifier")}

                  />
                </Form.Group>
              </Col>
            </Row>
            <div style={{ width: "fit-content", right: 0, marginLeft: "auto" }}>
              <Button
                variant="outline-secondary"
                onClick={() => {
                  close();
                }}
                style={{ width: 100 }}
                className="ml-3 mr-3"
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                style={{ width: 100 }}
                className="ml-3 mr-3"
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <Spinner animation="border" variant="light" size="sm" />
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </div>
        </Form>
      </Modal>
    </div>
  )
}
