import React from "react";
import { Paper } from "@material-ui/core";
import { Row, Col, Form, ListGroup, InputGroup } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Search } from "@material-ui/icons";
import axios from "axios";
import dayjs from "dayjs";
import useDebounce from "../../hooks/useDebounce";
import ExportExcel from "react-html-table-to-excel";
import { Dropdown } from "react-bootstrap";
import { MoreHoriz } from "@material-ui/icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import CreateSubscriptionModal from "./CreateSubscriptionModal";
import UpdateSubscriptionModal from "./UpdateSubscriptionModal";
import RevenueSharing from './RevenueSharingModal'
import ConfirmModal from '../../components/ConfirmModal'
import '../style.css'
import ModalUpdateOutelt from './ModalUpdateOutelt'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

toast.configure()

export function UserPage() {
  const [search, setSearch] = React.useState("");
  const debouncedSearch = useDebounce(search, 1000);
  const [filter, setFilter] = React.useState({
    status: "all",
    type: "all"
  });
  const [users, setUsers] = React.useState([]);
  const [availableFilter, setAvailableFilter] = React.useState({
    status: ["All"],
    type: ["All"]
  });
  const [dateLastGetData, setDateLastGetData] = React.useState(null);
  const [refresh, setRefresh] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState(null);
  const [showCreateSubsModal, setShowCreateSubsModal] = React.useState(false);
  const [showUpdateSubsModal, setShowUpdateSubsModal] = React.useState(false);
  const [showRevenueSharing, setShowRevenueSharing] = React.useState(false)

  const [stateDeleteModal, setStateDeleteModal] = React.useState(false)
  const [showModalUpdateOutlet, setShowModalUpdateOutlet] = React.useState(false)

  const enableLoading = () => setLoading(true);
  const disableLoading = () => setLoading(false);

  const handleRefresh = () => setRefresh(refresh + 1);
  const [selectedBusinessId, setSelectedBusinessId] = React.useState(null);

  const handleOpenCreateModal = () => setShowCreateSubsModal(true);
  const handleCloseCreateModal = () => setShowCreateSubsModal(false);

  const handleOpenUpdateModal = () => setShowUpdateSubsModal(true);
  const handleCloseUpdateModal = () => setShowUpdateSubsModal(false);

  const handleOpenRevenueSharing = () => setShowRevenueSharing(true)
  const handleCloseRevenueSharing = () => {
    setShowRevenueSharing(false)
    formikRevenueSharing.resetForm()
  }

  const handleSearch = (e) => setSearch(e.target.value);
  const handleFilter = (e) => {
    const { name, value } = e.target;
    const filterData = { ...filter };
    filterData[name] = value;
    setFilter(filterData);
  };

  const closeDeleteModal = () => setStateDeleteModal(false);
  const showDeleteModal = () => setStateDeleteModal(true)

  const handleDeleteBusiness = async () => {
    try {
      enableLoading()
      console.log("selectedBusinessId", selectedBusinessId)
      const API_URL = process.env.REACT_APP_API_URL;
      const {data} = await axios.delete(`${API_URL}/api/v1/business/${selectedBusinessId}`)
      console.log("data.data", data.data)
      if (data.data == "Delete Success") {
        toast.success('Delete Business Success', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        getUsers(debouncedSearch, filter);
        setDateLastGetData(new Date());
        disableLoading()
        closeDeleteModal()
      }
    } catch (error) {
      console.log("error", error)
      disableLoading()
    }
  }

  const getUsers = async (search, filter) => {
    const API_URL = process.env.REACT_APP_API_URL;
    let filterUser = "?";
    if (search) filterUser += `name=${search}`;
    // if (filter.status !== "all") filterUser += `&status=${filter.status}`;
    if (filter.type !== "all") filterUser += `&type=${filter.type}`;
    filterUser = filterUser === "?" ? "" : filterUser;
    try {
      const { data } = await axios.get(
        `${API_URL}/api/v1/business${filterUser}`
      );
      console.log("semua business", data.data)
      // filter user by Subscription status
      let filtered;
      if (filter.status.toLowerCase() === "none") {
        filtered = data.data.filter((item) => item.Subscription === null);
      } else if (filter.status !== "all") {
        filtered = data.data.filter(
          (item) =>
            item.Subscription?.status.toLowerCase() ===
            filter.status.toLowerCase()
        );
      } else {
        filtered = data.data;
      }
      setUsers(filtered);
    } catch (err) {
      setUsers([]);
    }
  };
  React.useEffect(() => {
    getUsers(debouncedSearch, filter);
    setDateLastGetData(new Date());
  }, [filter, debouncedSearch, refresh]);

  const initialSubscription = {
    business_id: 1,
    subscription_type_id: 1,
    expired_date: ""
  };

  const initialUpdateSbscription = {
    business_id: 1,
    subscription_id: 1,
    subscription_partition_id: 3,
    cz_user: "",
    cz_pin: "",
    cz_entity_id: "",
    cz_vendor_identifier: "",
    doku_client_id: "",
    doku_shared_key: "",
    doku_client_id_qris: "",
    doku_shared_key_qris: "",
    doku_client_secret: "",
    expired_date: "",
    status: false
  };

  const CreateSubscriptionSchema = Yup.object().shape({
    business_id: Yup.number().min(1),
    subscription_type_id: Yup.number()
      .min(1)
      .required("Please input Subscription Type"),
    expired_date: Yup.date().required("Please input expiry date")
  });

  const UpdateSubscriptionSchema = Yup.object().shape({
    business_id: Yup.number().min(1),
    subscription_type_id: Yup.number()
      .min(1)
      .required("Please input Subscription Type"),
    expired_date: Yup.date().required("Please input expiry date"),
    status: Yup.boolean().required("Please input status")
  });

  const formikCreateSubscription = useFormik({
    enableReinitialize: true,
    initialValues: initialSubscription,
    validationSchema: CreateSubscriptionSchema
    // onSubmit: async (values) => {
    //   const subscriptionData = {
    //     business_id: values.business_id,
    //     subscription_type_id: values.subscription_type_id,
    //     expired_date: values.expired_date,
    //     status: values.status
    //   };
    //   const paidStatus = values.paid;

    //   const API_URL = process.env.REACT_APP_API_URL;
    //   try {
    //     enableLoading();
    //     await axios.post(`${API_URL}/api/v1/subscription/`, subscriptionData);
    //     handleRefresh();
    //     disableLoading();
    //     handleCloseCreateModal();
    //   } catch (err) {
    //     setAlert(err.response?.data.message || err.message);
    //     disableLoading();
    //   }
    // }
  });

  const formikUpdateSubscription = useFormik({
    enableReinitialize: true,
    initialValues: initialUpdateSbscription,
    validationSchema: UpdateSubscriptionSchema
  });

  const initialOutlet = {
    id: "",
    cz_user: "",
    cz_pin: "",
    cz_entity_id: "",
    cz_vendor_identifier: ""
  }

  const formikUpdateOutlet = useFormik({
    enableReinitialize: true,
    initialValues: initialOutlet,
    onSubmit: (async (values, {resetForm}) => {
      const API_URL = process.env.REACT_APP_API_URL;
      try {
        enableLoading()
        const dataSend = {
          cz_user: values.cz_user,
          cz_pin: values.cz_pin,
          cz_entity_id: values.cz_entity_id,
          cz_vendor_identifier: values.cz_vendor_identifier
        }
        await axios.patch(`${API_URL}/api/v1/outlet/patch-cashlez-information/${values.id}`, dataSend)
        handleRefresh();
        resetForm()
        disableLoading()
        closeModalUpdateOutlet()
      } catch (error) {
        console.log(error)
        disableLoading()
      }
    })
  })

  const openModalUpdateOutlet = (row) => {
    console.log("datanye", row)
    formikUpdateOutlet.setValues({
      id: row.value.id,
      cz_user: row.value.cz_user,
      cz_pin: row.value.cz_pin,
      cz_entity_id: row.value.cz_entity_id,
      cz_vendor_identifier: row.value.cz_vendor_identifier
    })
    setShowModalUpdateOutlet(true)
  }

  const closeModalUpdateOutlet = () => setShowModalUpdateOutlet(false)

  const validationCreateSubscription = (fieldname) => {
    if (
      formikCreateSubscription.touched[fieldname] &&
      formikCreateSubscription.errors[fieldname]
    ) {
      return "is-invalid";
    }

    if (
      formikCreateSubscription.touched[fieldname] &&
      !formikCreateSubscription.errors[fieldname]
    ) {
      return "is-valid";
    }

    return "";
  };

  const validationUpdateSubscription = (fieldname) => {
    if (
      formikCreateSubscription.touched[fieldname] &&
      formikCreateSubscription.errors[fieldname]
    ) {
      return "is-invalid";
    }

    if (
      formikCreateSubscription.touched[fieldname] &&
      !formikCreateSubscription.errors[fieldname]
    ) {
      return "is-valid";
    }

    return "";
  };

  const columns = [
    {
      name: "No.",
      selector: "no",
      sortable: true,
      width: "50px"
    },
    {
      name: "Name",
      selector: "name",
      sortable: true
    },
    {
      name: "Location",
      selector: "location",
      sortable: true
    },
    {
      name: "Start Date",
      selector: "start_date",
      sortable: true
    },
    {
      name: "End Date",
      selector: "end_date",
      sortable: true
    },
    {
      name: "Subscription Status",
      selector: "status",
      sortable: true
    },
    {
      name: "Type",
      selector: "subscription_type",
      sortable: true
    },
    {
      name: "Action",
      selector: "business_id",
      key: "business_id",
      cell: (val) => {
        return (
          <Dropdown>
            <Dropdown.Toggle variant="light">
              <MoreHoriz />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                onClick={() => {
                  setSelectedBusinessId(val.business_id);
                  handleOpenUpdateModal();
                }}
              >
                Update Subscription Details
              </Dropdown.Item>
              {/* <Dropdown.Item
                as="button"
                onClick={() => {
                  getRevenueSharing(val.business_id)
                  setSelectedBusinessId(val.business_id);
                  handleOpenRevenueSharing();
                }}
              >
                Revenue Sharing
              </Dropdown.Item> */}
              <Dropdown.Item
                as="button"
                onClick={() => {
                  setSelectedBusinessId(val.business_id);
                  showDeleteModal()
                }}
              >
                Delete Business Account
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ];

  const dataUser = users.map((item, index) => {
    if (!availableFilter.status.includes(item.Subscription?.status)) {
      availableFilter.status.push(item.Subscription?.status);
    }
    if (!availableFilter.type.includes(item.subscription_type)) {
      availableFilter.type.push(item.subscription_type);
    }
    if (!item.Subscription && !availableFilter.status.includes("None")) {
      availableFilter.status.push("None");
    }
    const start_date = dayjs(item.createdAt).format("DD/MM/YYYY");
    const end_date = item.Subscription
      ? dayjs(item.Subscription.expired_date).format("DD/MM/YYYY")
      : "-";
    return {
      no: index + 1,
      business_id: item.id,
      name: item.name,
      outlets: item.Outlets,
      location: item.Location
        ? item.Location.City.name
        : "Location data not found",
      start_date,
      end_date,
      status: item.Subscription ? item.Subscription.status : "None", //klau belum ada Subcription otomatis dianggap NONE subscriptionnya
      type: item.subscription_type,
      subscription_type: item.Subscription
        ? item.Subscription.Subscription_Type.name
        : "Trial"
    };
  });
  console.log("dataUser", dataUser)

  const ExpandableComponent = ({ data }) => {
    let keys = [];
    data.outlets.forEach((val, idx) => {
      keys.push({
        key: idx,
        value: {
          id: val.id,
          name: val.name,
          status: val.status,
          address: val.address ? val.address : "-",
          location: val.Location ? val.Location.name : "-",
          city: val.Location ? val.Location.City.name : "-",
          cz_user: val.cz_user ? val.cz_user : null,
          cz_pin: val.cz_pin ? val.cz_pin : null,
          cz_entity_id: val.cz_entity_id ? val.cz_entity_id : null,
          cz_vendor_identifier: val.cz_vendor_identifier ? val.cz_vendor_identifier : null,
          // province: val.Location ? val.Location.City.Province.name : "-"
        }
      });
    });

    return (
      <>
        <ListGroup style={{ padding: "1rem", marginLeft: "1rem" }}>
          <ListGroup.Item key="head">
            <Row className="lineBottom">
              <Col md={2} style={{ fontWeight: "700" }}>
                Outlet Name
              </Col>
              <Col md={2} style={{ fontWeight: "700" }}>
                Address
              </Col>
              <Col md={2} style={{ fontWeight: "700" }}>
                Location
              </Col>
              <Col md={2} style={{ fontWeight: "700" }}>
                City
              </Col>
              {/* <Col md={2} style={{ fontWeight: "700" }}>
                Province
              </Col> */}
              <Col md={2} style={{ fontWeight: "700" }}>
                Status
              </Col>
              <Col md={2} style={{ fontWeight: "700" }}>
                Actions
              </Col>
            </Row>
          </ListGroup.Item>
          {keys.map((val, index) => {
            return (
              <ListGroup.Item key={index}>
                <Row>
                  <Col md={2}>{val.value.name}</Col>
                  <Col md={2}>
                    <div className="word-wrap">
                      {val.value.address}
                    </div>
                  </Col>
                  <Col md={2}>{val.value.location}</Col>
                  <Col md={2}>{val.value.city}</Col>
                  {/* <Col md={2}>{val.value.province}</Col> */}
                  <Col md={2}>{val.value.status}</Col>
                  <Col>
                    <Dropdown>
                      <Dropdown.Toggle variant="light">
                        <MoreHoriz />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          as="button"
                          onClick={() => {
                            // getTenantUtilityPayment(val.value.id)
                            // setTenantUtilityPaymentId(val.value.id)
                            openModalUpdateOutlet(val);
                          }}
                        >
                          Update Outlet
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              </ListGroup.Item>
            );
          })}
        </ListGroup>
      </>
    );
  };

  const handleAddSubscription = async (data) => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      console.log("ini data yang akan dikirim", data)
      console.log("Users", users)
      console.log("users.Subscription.data", users[0].Subscription)
      if (!users[0].Subscription) {
        console.log(true)
        enableLoading();
        await axios.post(`${API_URL}/api/v1/subscription/`, data);
      } else {
        console.log(false)
        enableLoading();
        const saveData = {
          business_id: selectedBusinessId,
          subscription_type_id: formikCreateSubscription.getFieldProps(
            "subscription_type_id"
          ).value,
          expired_date: formikCreateSubscription.getFieldProps("expired_date")
          .value,
          status: formikUpdateSubscription.getFieldProps("status").value
            ? "active"
            : "inactive"
        };
        console.log("ini save data", saveData)
        await handleUpdateSubscription(users[0].Subscription.id, saveData);
      }
      disableLoading();
      handleRefresh();
    } catch (err) {
      console.log("error handleAddSubscription", err)
      return err.response.status;
    }
  };

  const handleUpdateSubscription = async (subscription_id, data) => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      console.log("handle update subscription data", data)
      
      const resSubsPartitionPrivileges = await axios.get(`${API_URL}/api/v1/subscription-partition-privilege?subscription_partition_id=${data.subscription_partition_id}`);
      const privilegeDataOwner = resSubsPartitionPrivileges.data.data.map((item) => {
        return {
          id: item.Privilege.id,
          allow: false,
          name: item.Privilege.name,
          access: item.Privilege.Access.name,
          allowShow: item.allow
        };
      });

      console.log("privilegeDataOwner", privilegeDataOwner)
      console.log("resSubsPartitionPrivileges", resSubsPartitionPrivileges)

      const dataRoles = await axios.get(`${API_URL}/api/v1/role?businessId=${data.business_id}`);

      console.log("dataRoles", dataRoles)

      for (const [index, value] of dataRoles.data.data.entries()) {
        for (const value2 of privilegeDataOwner) {
          for (const value3 of value.Role_Privileges) {
            if(value2.id === value3.privilege_id && !value2.allowShow) {
              console.log("yang tidak masuk pengkondisian", value3)
              await axios.put(`${API_URL}/api/v1/role/update-privilege`, {
                id: value3.id,
                allow: false
              })
            }
          }
        }
      }

      console.log("handle update subscription subscription_id", subscription_id)

      enableLoading();
      enableLoading();
      await axios.put(
        `${API_URL}/api/v1/subscription/${subscription_id}`,
        data
      );
      const dataBusiness = {}
      if(data.cz_user) {
        dataBusiness.cz_user = data.cz_user
      }
      if(data.cz_pin){
        dataBusiness.cz_pin = data.cz_pin
      }
      if(data.cz_entity_id){
        dataBusiness.cz_entity_id = data.cz_entity_id
      }
      if(data.cz_vendor_identifier){
        dataBusiness.cz_vendor_identifier = data.cz_vendor_identifier
      }
      if(data.doku_client_id){
        dataBusiness.doku_client_id = data.doku_client_id
      }
      if(data.doku_shared_key){
        dataBusiness.doku_shared_key = data.doku_shared_key
      }
      if(data.doku_client_id_qris){
        dataBusiness.doku_client_id_qris = data.doku_client_id_qris
      }
      if(data.doku_shared_key_qris){
        dataBusiness.doku_shared_key_qris = data.doku_shared_key_qris
      }
      if(data.doku_client_secret){
        dataBusiness.doku_client_secret = data.doku_client_secret
      }
      console.log("dataBusiness", dataBusiness)

      await axios.patch(
        `${API_URL}/api/v1/business/key-payment-gateway/${selectedBusinessId}`,
        dataBusiness
      );

      disableLoading();
      handleRefresh();
    } catch (err) {
      console.log("err update subscription", err.response.status)
      return err.response.status;
    }
  };

  const initialValueRevenueSharing = {
    business_id: 0,
    electricity_cost: 0,
    water_cost: 0,
    gas_cost: 0,
    electricity_subscription_cost: 0,
    manager_percent_share: 0,
    business_percent_share: 0
  };

  const RevenueSharingSchema = Yup.object().shape({
    electricity_cost: Yup.number()
      .min(1, 'Minimum 1 Character'),
    water_cost: Yup.number()
      .min(1, 'Minimum 1 Character'),
    gas_cost: Yup.number()
      .min(1, 'Minimum 1 Character'),
    electricity_subscription_cost: Yup.number()
      .min(1, 'Minimum 1 Character'),
    manager_percent_share: Yup.number()
      .min(1, 'Minimum 1 Character'),
    business_percent_share: Yup.number()
      .min(1, 'Minimum 1 Character')
  });

  const formikRevenueSharing = useFormik({
    initialValues: initialValueRevenueSharing,
    validationSchema: RevenueSharingSchema,
    onSubmit: async (values) => {
      const API_URL = process.env.REACT_APP_API_URL;
      try {
        const data = {
          business_id: selectedBusinessId,
          electricity_cost: values.electricity_cost,
          water_cost: values.water_cost,
          gas_cost: values.water_cost,
          electricity_subscription_cost: values.electricity_subscription_cost,
          manager_percent_share: values.manager_percent_share,
          business_percent_share: values.business_percent_share
        }
        console.log("Data sebelum dikirim", data)

        enableLoading();
        await axios.post(`${API_URL}/api/v1/business-revenue-sharing`, data)
        toast.success('Success insert data', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        disableLoading();
        handleCloseRevenueSharing()
      } catch (err) {
        console.log("error", err)
        toast.error("Woahhh!! Something wen't wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setAlert(err.response.data.message);
        disableLoading();
        handleCloseRevenueSharing()
      }
    }
  });

  const handleSaveRevenueSharing = () => {
    try {
      formikRevenueSharing.submitForm()
    } catch (error) {
      console.log("error save revenue sharing", error)
    }
  }

  const getRevenueSharing = async (business_id) => {
    const API_URL = process.env.REACT_APP_API_URL;
    try {
      const {data} = await axios.get(`${API_URL}/api/v1/business-revenue-sharing/business-id/${business_id}`)
      formikRevenueSharing.setValues({
        electricity_cost: data.data.electricity_cost,
        water_cost: data.data.water_cost,
        gas_cost: data.data.gas_cost,
        electricity_subscription_cost: data.data.electricity_subscription_cost,
        manager_percent_share: data.data.manager_percent_share,
        business_percent_share: data.data.business_percent_share
      });
      console.log("getRevenueSharing", data.data)
    } catch (error) {

      console.log("error getRevenueSharing", error)
    }
  }

  React.useEffect(() => {

  })

  return (
    <>
      {/* Add Modal here */}
      <CreateSubscriptionModal
        state={showCreateSubsModal}
        business_id={selectedBusinessId}
        handleCloseSubsModal={handleCloseCreateModal}
        formikCreateSubscription={formikCreateSubscription}
        validationCreateSubscription={validationCreateSubscription}
        handleAddSubscription={handleAddSubscription}
      />
      <UpdateSubscriptionModal
        state={showUpdateSubsModal}
        business_id={selectedBusinessId}
        handleCloseSubsModal={handleCloseUpdateModal}
        formikUpdateSubscription={formikUpdateSubscription}
        validationUpdateSubscription={validationUpdateSubscription}
        handleUpdateSubscription={handleUpdateSubscription}
        businessMaster={users}
      />
      <RevenueSharing
        state={showRevenueSharing}
        handleCloseRevenueSharing={handleCloseRevenueSharing}
        handleSaveRevenueSharing={handleSaveRevenueSharing}
        formikRevenueSharing={formikRevenueSharing}
        // business_id={selectedBusinessId}
        // handleCloseSubsModal={handleCloseUpdateModal}
        // formikUpdateSubscription={formikUpdateSubscription}
        // validationUpdateSubscription={validationUpdateSubscription}
        // handleUpdateSubscription={handleUpdateSubscription}
        // businessMaster={users}
      />
      <ModalUpdateOutelt
        state={showModalUpdateOutlet}
        close={closeModalUpdateOutlet}
        formik={formikUpdateOutlet}
        loading={loading}
      />
      <div style={{ display: "none" }}>
        <table id="table-business">
          <thead>
            <tr>
              <th>
                <h3>User Data</h3>
              </th>
            </tr>
            <tr>
              <td>Report Date</td>
              <td>
                {dateLastGetData
                  ? dayjs(dateLastGetData).format("DD-MM-YYYY HH:mm")
                  : ""}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr></tr>
          </tbody>
          <thead>
            <tr>
              <th style={{ backgroundColor: "yellow" }}>Business Name</th>
              <th style={{ backgroundColor: "yellow" }}>Outlet</th>
              <th style={{ backgroundColor: "yellow" }}>Address</th>
              <th style={{ backgroundColor: "yellow" }}>Location</th>
              <th style={{ backgroundColor: "yellow" }}>City</th>
              <th style={{ backgroundColor: "yellow" }}>Province</th>
              <th style={{ backgroundColor: "yellow" }}>Phone Number</th>
              <th style={{ backgroundColor: "yellow" }}>Status</th>
              <th style={{ backgroundColor: "yellow" }}>Subscription Type</th>
            </tr>
          </thead>
          <tbody>
            {users.map((business, idx) => {
              return (
                <tbody>
                  <tr>
                    <td
                      rowSpan={business.Outlets.length}
                      style={{ verticalAlign: "middle" }}
                    >
                      {business.name}
                    </td>
                    {business.Outlets.map((outlet, idx) => {
                      if (idx === 0) {
                        return (
                          <>
                            <td>{outlet.name}</td>
                            <td>{outlet.address ? outlet.address : "-"}</td>
                            <td>
                              {outlet.Location ? outlet.Location.name : "-"}
                            </td>
                            <td>
                              {outlet.Location
                                ? outlet.Location.City.name
                                : "-"}
                            </td>
                            <td>
                              {outlet.Location
                                ? outlet.Location.City.Province.name
                                : "-"}
                            </td>
                            <td>
                              {outlet.phone_number ? outlet.phone_number : "-"}
                            </td>
                            <td>{outlet.status}</td>
                            <td
                              rowSpan={business.Outlets.length}
                              style={{
                                verticalAlign: "middle",
                                textAlign: "center"
                              }}
                            >
                              {business.subscription_type
                                ? business.subscription_type
                                : "-"}
                            </td>
                          </>
                        );
                      } else {
                        return (
                          <tr>
                            <td>{outlet.name}</td>
                            <td>{outlet.address ? outlet.address : "-"}</td>
                            <td>
                              {outlet.Location ? outlet.Location.name : "-"}
                            </td>
                            <td>
                              {outlet.Location
                                ? outlet.Location.City.name
                                : "-"}
                            </td>
                            <td>
                              {outlet.Location
                                ? outlet.Location.City.Province.name
                                : "-"}
                            </td>
                            <td>
                              {outlet.phone_number ? outlet.phone_number : "-"}
                            </td>
                            <td>{outlet.status}</td>
                          </tr>
                        );
                      }
                    })}
                  </tr>
                </tbody>
              );
            })}
          </tbody>
        </table>
      </div>
      <Row>
        <Col md={12}>
          <Paper elevation={2} style={{ padding: "1rem", height: "100%" }}>
            <div
              className="headerPage lineBottom d-flex"
              style={{ marginBottom: "1rem" }}
            >
              <div className="headerStart ">
                <h3 className="p-3" style={{ width: "fit-content" }}>
                  User Data
                  <small className="pl-3">BeetPOS Customer</small>
                </h3>
              </div>
              <div
                className="headerEnd"
                style={{ right: 0, marginLeft: "auto" }}
              >
                <ExportExcel
                  className="btn btn-primary font-weight-bolder px-5"
                  table="table-business"
                  filename={`Business-List-${dayjs(dateLastGetData).format(
                    "DD_MM_YYYY"
                  )}`}
                  sheet="Business-List"
                  buttonText="Export"
                />
              </div>
            </div>

            <div className="filterSection lineBottom mt-3">
              <Row>
                <Col>
                  <InputGroup className="pb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text style={{ background: "transparent" }}>
                        <Search />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      placeholder="Search..."
                      value={search}
                      onChange={handleSearch}
                    />
                  </InputGroup>
                </Col>

                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Status:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filter.status}
                        onChange={handleFilter}
                      >
                        {availableFilter.status.map((value, idx) => {
                          if (value)
                            return (
                              <option value={value.toLowerCase()} key={value}>
                                {value.charAt(0).toUpperCase() + value.slice(1)}
                              </option>
                            );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group as={Row}>
                    <Form.Label
                      style={{ alignSelf: "center", marginBottom: "0" }}
                    >
                      Type:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        as="select"
                        name="type"
                        value={filter.type}
                        onChange={handleFilter}
                      >
                        {availableFilter.type.map((value, idx) => {
                          if (value)
                            return (
                              <option value={value.toLowerCase()} key={value}>
                                {value.charAt(0).toUpperCase() + value.slice(1)}
                              </option>
                            );
                        })}
                      </Form.Control>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </div>

            <DataTable
              noHeader
              pagination
              columns={columns}
              data={dataUser}
              expandableRows
              expandableRowsComponent={<ExpandableComponent />}
              style={{ minHeight: "fit-content" }}
            />
          </Paper>
        </Col>
      </Row>

      <ConfirmModal
        title="Delete Business"
        body="Are you sure delete this Business Account"
        buttonColor="danger"
        state={stateDeleteModal}
        closeModal={closeDeleteModal}
        handleClick={() => handleDeleteBusiness()}
        loading={loading}
      />
    </>
  );
}
