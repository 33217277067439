import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";

import { DashboardPage } from "./pages/DashboardPage";
import { UserPage } from "./pages/User/UserPage";
import RevenueSharing from "./pages/RevenueSharing/RevenueSharingPage";
import { TransactionPage } from "./pages/Transaction/TransactionPage";
import { LimitSettingPage } from "./pages/LimitSetting/LimitSettingPage";
import Payment from "./pages/Payment/PaymentPage";
import PaymentGateway from "./pages/PaymentGateway/PaymentGatewayPage";
import { CurrencyPage } from "./pages/Currency/CurrencyPage";
import { MembershipRankPage } from "./pages/MembershipRank/MembershipRankPage";
import RevenueSharingDetail from './pages/RevenueSharing/Detail'

// Sama persis seperti UserPage, cman di bagian dropdown outlet nya dikasih action untuk update integrate online shop
import { IntegrateOnlieShop } from './pages/IntegrateOnlineShop/UserPage'
import { RequestIntegration } from './pages/IntegrateOnlineShop/RequestIntegration'

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Route path="/dashboard" component={DashboardPage} />
        <Route path="/user" component={UserPage} />
        <Route path="/revenue-sharing" exact component={RevenueSharing} />
        <Route path="/revenue-sharing/detail/:id" component={RevenueSharingDetail} />
        <Route path="/integrate-online-shop" component={IntegrateOnlieShop} />
        <Route path="/request-integration" component={RequestIntegration} />

        <Route path="/membership-rank" component={MembershipRankPage} />
        <Route path="/transaction" component={TransactionPage} />
        <Route path="/limit-setting" component={LimitSettingPage} />
        <Route path="/payment" component={Payment} />
        <Route path="/gateway" component={PaymentGateway} />
        <Route path="/currency" component={CurrencyPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
